<template>
    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :cols="12">
                <seo-visibility-visibility-score/>
            </v-col>


            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <seo-visibility-incoming-traffic/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <seo-visibility-conversion/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <seo-visibility-seo-turnover/>
            </v-col>


            <v-col :xl="6" :lg="6" :md="6" :cols="12">
                <seo-visibility-top-position-count/>
            </v-col>
            <v-col :xl="6" :lg="6" :md="6" :cols="12">
                <seo-visibility-position-repartition/>
            </v-col>


            <v-col :cols="12">
                <seo-visibility-natural-position-list/>
            </v-col>


            <v-col :cols="12">
                <seo-visibility-compare-keywords-positions/>
            </v-col>
        </v-row>
    </page-placeholder>
</template>


<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import SeoVisibilityVisibilityScore from "@/components/yooda-components/app/seo-visibility/SeoVisibilityVisibilityScore";
import SeoVisibilityIncomingTraffic from "@/components/yooda-components/app/seo-visibility/SeoVisibilityIncomingTraffic";
import SeoVisibilityConversion from "@/components/yooda-components/app/seo-visibility/SeoVisibilityConversion";
import SeoVisibilitySeoTurnover from "@/components/yooda-components/app/seo-visibility/SeoVisibilitySeoTurnover";
import SeoVisibilityTopPositionCount from "@/components/yooda-components/app/seo-visibility/SeoVisibilityTopPositionCount";
import SeoVisibilityPositionRepartition from "@/components/yooda-components/app/seo-visibility/SeoVisibilityPositionRepartition";
import SeoVisibilityNaturalPositionList from "@/components/yooda-components/app/seo-visibility/SeoVisibilityNaturalPositionList";
import SeoVisibilityCompareKeywordsPositions from "@/components/yooda-components/app/seo-visibility/SeoVisibilityCompareKeywordsPositions";

export default {
    components: {
        PagePlaceholder,
        SeoVisibilityVisibilityScore,
        SeoVisibilityIncomingTraffic,
        SeoVisibilityConversion,
        SeoVisibilitySeoTurnover,
        SeoVisibilityTopPositionCount,
        SeoVisibilityPositionRepartition,
        SeoVisibilityNaturalPositionList,
        SeoVisibilityCompareKeywordsPositions
    },
}

</script>


